import { Box, Container, Flex } from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMenu } from "../store/MenuContext";
import NavbarBottom from "../layout/NavbarBottom";
import { useQuery } from "react-query";
import { fetchCategoryList } from "../api/api";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { useBasket } from "../store/BasketContext";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ProductCard from "../components/ProductCard";
import FavoriteProduct from "../components/FavoriteProduct";
import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";
import Category from "../components/Category";

function Categories() {
  const [search, setSearch] = useState("");
  const [productAll, setProductAll] = useState(null);
  const { t } = useTranslation();
  const { categories, setCategories } = useMenu();
  const productURL = `${process.env.REACT_APP_BASE_ENDPOINT}/api/productall`;
  const { addToBasket } = useBasket();
  const notify = () => toast("Ürün Sepete Eklendi!");

  const addBasketAll = (e, item) => {
    e.preventDefault();
    addToBasket(item);
    notify();
  };

  const getProductAllApi = () => {
    axios.get(productURL).then((response) => {
      setProductAll(response.data);
    });
  };

  const { isLoading, error, data } = useQuery("categories", fetchCategoryList);
  useEffect(() => {
    setCategories(data);
  }, [data, setCategories]);

  if (isLoading) return <Loading height="100vh" />;
  if (error) return "An error has occurred: " + error.message;

  return (
    <Fragment>
      <Navbar />
      <Link to="/">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>

      <Container mb={100}>
        <Form>
          <InputGroup className="my-3">
            <FormControl
              onClick={getProductAllApi}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("Ürün ara...")}
            />
          </InputGroup>
        </Form>

        <Box>
          <FavoriteProduct />
        </Box>

        {productAll
          ?.filter((item) => {
            return search.trim() == ""
              ? null
              : item.title.toLowerCase().includes(search.toLowerCase());
          })
          .map((product) => (
            <ProductCard
              key={product.id}
              item={product}
              addBasketAll={addBasketAll}
            />
          ))}
        <ToastContainer autoClose={2000} />

        {categories?.map((item) => {
          return <Category key={item.id} item={item} />;
        })}
      </Container>
      <NavbarBottom />
    </Fragment>
  );
}

export default Categories;
