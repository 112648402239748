import axios from "axios";

export const fetchCategoryList = async () => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/category`);

    return data.categories;
}

export const fetchCategory = async (category_id) => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/category/${category_id}`);

  return data;
}

export const fetchProductList = async (category_id) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/product/${category_id}`);

    return data;
}

export const fetchFavoriteProducts = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/favorite_products`);

  return data;
}


export const fetchProductDetailPhotos = async (product_id) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/images/${product_id}`);

    return data;
}

export const fetchProductDetails = async (product_id) => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/product_details/${product_id}`);

  return data;
}

export const fetchSong = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/songdetail`);

  return data;
}

export const fetchLogin = async(input) => {
    const {data} = await axios.post(`${process.env.REACT_APP_BASE_ENDPOINT}/api/login`,input)

    return data;
}


export const fetchMe = async () => {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/me`,
        data: { _token: localStorage.getItem('access-token') },
        headers: { 
          "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
        }
      };
    
      const data = await axios(config)
        .then(function (response) {
         return response.data;
        })
        .catch(function (error) {
          console.log(error);
          throw error; 
        });  
    return data
}


export const fetchLogout = async () => {
    const {data} = await axios.post(`${process.env.REACT_APP_BASE_ENDPOINT}/api/logout`,{
    })

    return data;
}


export const postOrder = async (input) => {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/order`,
        data: input,
        headers: { 
          "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
        },
      };
      const data = await axios(config)
        .then(function (response) {
         return response.data;
        })
        .catch(function (error) {
            return error;
        });  
    return data
}
export const postSongStatus = async (input) => {
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/songStatusUpdate`,
      data: {song_status:input},
      headers: { 
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
      },
    };
    const data = await axios(config)
      .then(function (response) {
       return response.data;
      })
      .catch(function (error) {
          return error;
      });  
  return data
}


export const postWaiter = async (input) => {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/waiter`,
        data: input,
        headers: { 
          "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
        },
      };
      const data = await axios(config)
        .then(function (response) {
         return response.data;
        })
        .catch(function (error) {
            return error;
        });  
    return data
}


export const postSong = async (input) => {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/song`,
        data: input,
        headers: { 
          "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
        },
      };
      const data = await axios(config)
        .then(function (response) {
         return response.data;
        })
        .catch(function (error) {
            return error;
        });  
    return data
}

export const postReview = async (input) => {
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/review`,
      data: input,
      headers: { 
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
      },
    };
    const data = await axios(config)
      .then(function (response) {
       return response.data;
      })
      .catch(function (error) {
          return error;
      });  
  return data
}


export const accoundShow = async () => {
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/account`,
      data: { _token: localStorage.getItem('access-token') },
      headers: { 
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
      }
    };
  
    const data = await axios(config)
      .then(function (response) {
          return response.data;
      })
      .catch(function (error) {
        throw error; 
      });  
  return data
}

export const orderStatus = async () => {
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/order_status`,
      data: { _token: localStorage.getItem('access-token') },
      headers: { 
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
      }
    };
  
    const data = await axios(config)
      .then(function (response) {
       return response.data;
      })
      .catch(function (error) {
          throw error;
      });  
  return data
}

export const accountRequest = async (input) => {
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/account_request`,
      data: input,
      headers: { 
        "Authorization": `Bearer ${localStorage.getItem('access-token')}`, 
      },
    };
    const data = await axios(config)
      .then(function (response) {
       return response.data;
      })
      .catch(function (error) {
          return error;
      });  
  return data
}

export const orderSettings = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/order_status`);

  return data == "1";
}

export const songRequestSettings = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/song_request_status`);

  return data == "1";
}

export const waiterRequestSettings = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/waiter_request_status`);

  return data == "1";
}


export const cancel_time = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/cancel_time`);

  return data;
}

export const settings = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_BASE_ENDPOINT}/api/settings`);

  return data;
}


